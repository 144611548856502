import { AirlinePartner } from '@/types/master';
import {
  useConfigStore,
  useMasterService,
  normalizeSlug,
  useAsyncData,
  useRuntimeConfig,
  useNavigation,
} from '#imports';

export const useAirlinePartner = (limit: number) => {
  const { data: airlinePartners } = useAsyncData(
    () => useMasterService().getAirlineListByCountry({ country: useConfigStore().country! }),
    {
      transform: (res) => res.result.slice(0, limit),
    },
  );

  const getAirlineLogoUrl = (code: string) => `${useRuntimeConfig().public.cdnAssetUrl}/airlines/201x201/${code}.png`;

  const getAirlinePath = (airline: AirlinePartner) =>
    useNavigation().navigationPath(`/airlines/${airline.code}-${normalizeSlug(airline.name)}`);

  return {
    airlinePartners,
    getAirlineLogoUrl,
    getAirlinePath,
  };
};
